<script>
export default {
  metaInfo: {
    title: "Reset password",
  }
};
</script>

<script setup>
import {getURLSearchParam} from "@/js/general";
import XForm from "@/components/basic/XForm.vue";
import XTextField from "@/components/basic/XTextField.vue";
import XBtn from "@/components/basic/XBtn.vue";
import HeadlineBox from "@/components/basic/HeadlineBox.vue";
import {ref} from "vue";
import authService from "@/js/services/AuthService";
import {useNotifications} from "@/composition/notifications";
import {useRouter} from "vue-router/composables";

const router = useRouter();
const {showNotification} = useNotifications()

const resetPasswordForm = ref(null);

const code = getURLSearchParam('code');
const email = getURLSearchParam('email');

const temporaryPassword = ref('');
const newPassword = ref('');
const confirmNewPassword = ref('');

const temporaryPasswordRules = ref([])
const newPasswordRules = [
  v => v.length >= 8 || 'Must be at least 8 characters long.',
  v => /[A-Z]/.test(v) || 'Must contain at least one uppercase letter.',
  v => /[a-z]/.test(v) || 'Must contain at least one lowercase letter.',
  v => /\d/.test(v) || 'Must contain at least one number.',
  v => /[\W_]/.test(v) || 'Must contain at least one special character.'
];

const submit = () => {
  const isValid = resetPasswordForm.value.validate();
  if (!isValid) {
    return;
  }

  const temporaryPasswordValue = temporaryPassword.value;

  authService.resetPasswordCockpit(email, code, temporaryPasswordValue, newPassword.value)
      .then(() => {
        showNotification({message: 'Password has been reset successfully.'});
        router.push({name: 'login'});
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          const data = error.response.data;
          if (data.error === 'AUTH_SERVICE_CODE_INCORRECT') {
            showNotification({message: 'The code is incorrect. Please make sure to follow the link in the email.'});
            return;
          }
          if (data.error === 'AUTH_SERVICE_TEMPORARY_PASSWORD_INCORRECT') {
            showNotification({message: 'The temporary password is incorrect.'});
            temporaryPasswordRules.value = [v => v !== temporaryPasswordValue || 'The temporary password is incorrect.'];
            return;
          }
        }
        showNotification({message: 'An unexpected error occurred. Please contact Acctopus.'});
      });
}
</script>

<template>
  <div class="reset-password-view">
    <HeadlineBox headline="Set Password">
      <XForm ref="resetPasswordForm" class="reset-password-form">
        <XTextField label="Email" v-model="email" readonly/>
        <XTextField
            label="Temporary Password"
            v-model="temporaryPassword"
            type="password"
            required
            :rules="temporaryPasswordRules"/>
        <XTextField label="New Password" v-model="newPassword" type="password" required :rules="newPasswordRules"/>
        <XTextField
            label="Confirm New Password"
            v-model="confirmNewPassword"
            type="password"
            :rules="[v => v === newPassword || 'Passwords do not match.']"
            required/>
        <XBtn text="Set password" color="save" icon="mdi-lock" @click="submit"/>
      </XForm>
    </HeadlineBox>
  </div>
</template>

<style scoped>
.reset-password-view {
  margin: 20px auto;
  width: 400px;
}

.reset-password-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>
